import { lazy } from 'react';

// project-imports
import GuestGuard from 'utils/route-guard/GuestGuard';
import CommonLayout from 'layout/CommonLayout';
import Loadable from 'components/Loadable';

// render - login
const AuthLogin = Loadable(lazy(() => import('pages/auth/auth1/login')));
const AuthRegister = Loadable(lazy(() => import('pages/auth/auth1/register')));
const AuthForgotPassword = Loadable(lazy(() => import('pages/auth/auth1/forgot-password')));
const InfluencerForgotPassword = Loadable(lazy(() => import('pages/auth/auth1/influencer-forgot-password')));
const AuthCheckMail = Loadable(lazy(() => import('pages/auth/auth1/check-mail')));
const InfluencerAuthCheckMail = Loadable(lazy(() => import('pages/auth/auth1/influencer-check-mail')));
const AuthResetPassword = Loadable(lazy(() => import('pages/auth/auth1/reset-password')));
const AuthCodeVerification = Loadable(lazy(() => import('pages/auth/auth1/code-verification')));
const HomePage = Loadable(lazy(() => import('pages/auth/auth1/landing/home-page')));
const InfluencerRegister = Loadable(lazy(() => import('pages/auth/auth1/InfluencerAuth/influencerRegister')));
const InfluencerLogin = Loadable(lazy(() => import('pages/auth/auth1/InfluencerAuth/InfluencerLogin')));

// ==============================|| AUTH ROUTES ||============================== //

const LoginRoutes = {
  path: '/',
  children: [
    {
      path: '/',
      element: (
        <GuestGuard>
          <CommonLayout />
        </GuestGuard>
      ),
      children: [
        {
          path: '/',
          element: <HomePage />
        },
        {
          path: 'login',
          element: <AuthLogin />
        },
        {
          path: 'influencer/login',
          element: <InfluencerLogin />
        },
        {
          path: 'register',
          element: <AuthRegister />
        },
        {
          path: 'influencer/register',
          element: <InfluencerRegister />
        },
        {
          path: 'forgot-password',
          element: <AuthForgotPassword />
        },
        {
          path: '/influencer/forgot-password',
          element: <InfluencerForgotPassword />
        },
        {
          path: 'check-mail',
          element: <AuthCheckMail />
        },
        {
          path: '/influencer/check-mail',
          element: <InfluencerAuthCheckMail />
        },
        {
          path: 'reset-password',
          element: <AuthResetPassword />
        },
        {
          path: 'code-verification',
          element: <AuthCodeVerification />
        }
      ]
    }
  ]
};

export default LoginRoutes;
