// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { KyberNetwork, Messages2, Calendar1, Kanban, Profile2User, Bill, UserSquare, ShoppingBag, Moneys, ShoppingCart } from 'iconsax-react';

// icons
const icons = {
  // dashboard: HomeTrendUp,
  applications: KyberNetwork,
  chat: Messages2,
  calendar: Calendar1,
  kanban: Kanban,
  customer: Profile2User,
  invoice: Bill,
  profile: UserSquare,
  ecommerce: ShoppingBag,
  orders : ShoppingCart,
  earnings : Moneys
};

// ==============================|| MENU ITEMS - APPLICATIONS ||============================== //

const applications = {
  id: 'group-applications',
  title: <FormattedMessage id="applications" />,
  icon: icons.applications,
  type: 'group',
  children: [
    // {
    //   id: 'dashboard',
    //   title: <FormattedMessage id="dashboard" />,
    //   type: 'item',
    //   url: '/dashboard',
    //   icon: icons.dashboard,
    //   breadcrumbs: false
    // },
    // {
    //   id: 'chat',
    //   title: <FormattedMessage id="chat" />,
    //   type: 'item',
    //   url: '/apps/chat',
    //   icon: icons.chat,
    //   breadcrumbs: false
    // },
    // {
    //   id: 'calendar',
    //   title: <FormattedMessage id="calendar" />,
    //   type: 'item',
    //   url: '/apps/calendar',
    //   icon: icons.calendar
    // },
    // {
    //   id: 'kanban',
    //   title: <FormattedMessage id="kanban" />,
    //   type: 'item',
    //   icon: icons.kanban,
    //   url: '/apps/kanban/board'
    // },
    // {
    //   id: 'customer',
    //   title: <FormattedMessage id="customer" />,
    //   type: 'collapse',
    //   icon: icons.customer,
    //   children: [
    //     {
    //       id: 'customer-list',
    //       title: <FormattedMessage id="list" />,
    //       type: 'item',
    //       url: '/apps/customer/customer-list'
    //     },
    //     {
    //       id: 'customer-card',
    //       title: <FormattedMessage id="cards" />,
    //       type: 'item',
    //       url: '/apps/customer/customer-card'
    //     }
    //   ]
    // },
    // {
    //   id: 'invoice',
    //   title: <FormattedMessage id="invoice" />,
    //   url: '/apps/invoice/dashboard',
    //   type: 'collapse',
    //   icon: icons.invoice,
    //   breadcrumbs: true,
    //   children: [
    //     {
    //       id: 'create',
    //       title: <FormattedMessage id="create" />,
    //       type: 'item',
    //       url: '/apps/invoice/create'
    //     },
    //     {
    //       id: 'details',
    //       title: <FormattedMessage id="details" />,
    //       type: 'item',
    //       url: '/apps/invoice/details/1'
    //     },
    //     {
    //       id: 'list',
    //       title: <FormattedMessage id="list" />,
    //       type: 'item',
    //       url: '/apps/invoice/list'
    //     },
    //     {
    //       id: 'edit',
    //       title: <FormattedMessage id="edit" />,
    //       type: 'item',
    //       url: '/apps/invoice/edit/1'
    //     }
    //   ]
    // },
    // {
    //   id: 'profile',
    //   title: <FormattedMessage id="profile" />,
    //   type: 'collapse',
    //   icon: icons.profile,
    //   children: [
    //     {
    //       id: 'user-profile',
    //       title: <FormattedMessage id="user-profile" />,
    //       type: 'item',
    //       url: '/apps/profiles/user/personal',
    //       breadcrumbs: false
    //     },
    //     {
    //       id: 'account-profile',
    //       title: <FormattedMessage id="account-profile" />,
    //       type: 'item',
    //       url: '/apps/profiles/account/basic'
    //     }
    //   ]
    // },
    // {
    //   id: 'dashboard',
    //   title: <FormattedMessage id="dashboard" />,
    //   type: 'collapse',
    //   url: '/dashboard',
    //   icon: icons.dashboard,
    //   breadcrumbs: false
    // },
    {
      id: 'products',
      title: <FormattedMessage id="products" />,
      type: 'collapse',
      icon: icons.ecommerce,
      breadcrumbs: true,
      children: [
        // {
        //   id: 'in-stock',
        //   title: <FormattedMessage id="in-stock" />,
        //   type: 'item',
        //   url: '/apps/e-commerce/products',
        //   breadcrumbs: false
        // },
        {
          id: 'add-product',
          title: <FormattedMessage id="add-product" />,
          type: 'item',
          url: '/products/add-product'
        },
        {
          id: 'product-list',
          title: <FormattedMessage id="product-list" />,
          type: 'item',
          url: '/products/product-list'
        },
        // {
        //   id: 'edit-product',
        //   title: <FormattedMessage id="edit-product" />,
        //   type: 'item',
        //   url: '/apps/e-commerce/edit-product'
        // },
        // {
        //   id: 'update-stock',
        //   title: <FormattedMessage id="update-stock" />,
        //   type: 'item',
        //   url: '/apps/e-commerce/edit-variant'
        // },
        {
          id: 'product-assignment',
          title: <FormattedMessage id="product-assignment" />,
          type: 'item',
          url: '/products/product-assignment'
        },
        {
          id: 'send-request',
          title: <FormattedMessage id="send-request" />,
          type: 'item',
          url: '/products/send-request'
        },
        {
          id: 'Product Rent',
          title: <FormattedMessage id="Product Rent" />,
          type: 'item',
          url: '/products/product-rent'
        }
      ]
    },
    {
      id: 'orders',
      title: <FormattedMessage id="orders" />,
      type: 'item',
      icon: icons.orders,
      url: '/orders'
    },
    {
      id: 'earnings',
      title: <FormattedMessage id="earnings" />,
      type: 'item',
      icon: icons.earnings,
      url: '/earnings'
    }
  ]
};

export default applications;
