import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

// project-imports
import { APP_DEFAULT_PATH } from 'config';
import useAuth from 'hooks/useAuth';

// ==============================|| GUEST GUARD ||============================== //

const GuestGuard = ({ children }) => {
  const { isLoggedIn, user } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (isLoggedIn && user) {
      // console.log(isLoggedIn, user)
      const role = user.role;

      let redirectPath = APP_DEFAULT_PATH; 
      if (role === 'influencer') {
        redirectPath = '/influencer/dashboard';
      }
      navigate(location?.state?.from ? location?.state?.from : redirectPath, {
        state: {
          from: ''
        },
        replace: true
      });
    }
  }, [isLoggedIn, user, navigate, location]);

  return children;
};

GuestGuard.propTypes = {
  children: PropTypes.node
};

export default GuestGuard;
