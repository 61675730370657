// // project-imports
// import applications from './applications';
// import widget from './widget';
// // import formsTables from './forms-tables';
// // import chartsMap from './charts-map';
// // import support from './support';
// // import pages from './pages';
// import influencerMenu from './influencer-menu';

// // ==============================|| MENU ITEMS ||============================== //

// const getRole = () => localStorage.getItem('role') || 'vendor';

// const menuItems = {
//   // items: [widget, applications, formsTables, chartsMap, pages, support]
//   items: getRole() === 'vendor' ? [widget, applications] : [influencerMenu]
// };

// export default menuItems;

// project-imports
import applications from './applications';
import widget from './widget';
import influencerMenu from './influencer-menu';
import useAuth from 'hooks/useAuth';

// ==============================|| MENU ITEMS ||============================== //

const MenuItems = () => {
  const { user } = useAuth(); 
  const role = user?.role || 'vendor'; 

  return {
    items: role === 'vendor' ? [widget, applications] : [influencerMenu]
  };
};

export default MenuItems;
