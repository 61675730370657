// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { HomeTrendUp,Story, Fatrows, PresentionChart } from 'iconsax-react';

// icons
const icons = {
  dashboard: HomeTrendUp,
  widgets: Story,
  statistics: Story,
  data: Fatrows,
  chart: PresentionChart
};

// ==============================|| MENU ITEMS - WIDGETS ||============================== //

const widget = {
  id: 'group-widget',
  title: <FormattedMessage id="widgets" />,
  icon: icons.widgets,
  type: 'group',
  children: [
    {
      id: 'dashboard',
      title: <FormattedMessage id="dashboard" />,
      type: 'item',
      url: '/influencer/dashboard',
      icon: icons.dashboard,
      breadcrumbs: false
    },
    {
      id: 'Promo Codes',
      title: <FormattedMessage id="Promo Codes" />,
      type: 'item',
      url: '/influencer/referral-code',
      icon: icons.statistics
    },
    // {
    //   id: 'Earnings Payouts',
    //   title: <FormattedMessage id="Earnings Payouts" />,
    //   type: 'item',
    //   url: '/influencer/payouts',
    //   icon: icons.data
    // }
  ]
};

export default widget;
